import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        paddingTop: 0,
        paddingBottom: 30,
        [theme.breakpoints.down("sm")]: {
            padding: 20,
        },
    },
    heading: {
        fontSize: "1.5rem",
        marginTop: "50px"
    },
    innerWrapper: {
        width: "100%",
        maxWidth: 1440,
        display: "flex",
        flexDirection: "column",
    },
    caption: {
        margin: '20px 5px 5px 5px',
        fontWeight: 'lighter',
        fontSize: 'large'
    }
}))

