import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import SubHero from "../components/SubHero"

import SecurityCommitment from "../sections/technology/SecurityCommitment";
import strategicAdvisoryBgUrl from "../../media/client-work-banner-overlay.png"
import NextService from "../sections/services/NextService";
import PrivacyPolicy from "../sections/privacy-policy";

const securityCommitmentTitle = 'Privacy Policy'
const securityCommitmentDescription = ''

const PrivacyPolicyPage = ({ location }: PageProps) => (

    <Layout location={location}>
        <SEO title={securityCommitmentTitle}/>
        <SubHero
            backgroundImage={strategicAdvisoryBgUrl}
            title={securityCommitmentTitle}
            description={securityCommitmentDescription}
        />
        <PrivacyPolicy/>
        <NextService
            text='Contact us here if you have any questions about Grandview’s Privacy Policy or the information practices of the Services.'
            link='/report-issue'/>
    </Layout>
)

export default PrivacyPolicyPage
