import * as React from "react"
import { Typography } from "@material-ui/core"

import { privacyList } from "./data"
import { useStyles } from "./styles"


const PrivacyPolicy = () => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.innerWrapper}>

                {privacyList.map((item, index) => (
                    <>
                        <Typography key={`privacy-notice-title-${index}`} variant='h6' className={classes.heading}>
                            {item.title}
                        </Typography>
                        <Typography key={`privacy-notice-description-${index}`} variant='h6' className={classes.caption}>
                            {item.description}
                        </Typography>
                    </>
                ))}
            </div>
        </div>
    )
}

export default PrivacyPolicy

